body {
   margin: 0;
   font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-decoration: none !important;
   font-size: 16px !important;
   font-weight: normal !important;
   color: #4d4d4d !important;
   line-height: 1.6 !important;
   word-spacing: 1px;
}
.footer {
   bottom: 0 !important;
}
.fab {
   text-align: center;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.activeSide {
   border-left: 4px solid green;
   font-weight: 600;
}

.box-shadow {
   box-shadow: 3px 4px 4px 0px rgb(0, 0, 0, 0.2);
   border-radius: 2%;
}

.box-shadow-bottom {
   box-shadow: 0 4px 4px -4px rgb(0, 0, 0, 0.2);
   border-radius: 2%;
}

.simple-box-shadow {
   box-shadow: 3px 4px 4px 0px rgb(0, 0, 0, 0.2);
}

.home-banner-txt {
   color: #536e40;
   font-size: xx-large;
   /* position: absolute; */
   position: relative !important;
   bottom: 0;
}

.home-banner-txt span {
   position: absolute;
   bottom: 15%;
   left: 5%;
}

.zoom {
   transition: transform 0.2s;
}

.zoom:hover {
   transform: scale(1.05);
}

/* For footer */
.footer {
   background: #719a97;
   color: white;
   position: sticky;
   /* bottom: 0; */
   width: 100%;
}

.slider-wrapper {
   width: 95%;
   height: 200%;
   background-color: #eeeeee;
   margin: auto;
   margin-bottom: 5%;
   vertical-align: middle;
}

.white {
   color: white;
}

.dropdown-toggle::after {
   display: none !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
   outline: none;
   box-shadow: none;
}
