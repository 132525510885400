/* .navbar-nav .dropdown-menu {
  background-color: black;
  color: red !important;
} */

/* .navbar-nav > li > .dropdown-menu li > a {
  color: red !important;
} */

.footer-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-li {
  display: inline;
  padding: 0;
  margin: 0;
}

.auto {
  margin: auto !important;
}
.featuredin {
  background-color: #f7f7f7;
}

.opacity {
  background: rgb(215, 223, 33, 0.85);
}

.common-banner-text {
  color: #536e40;
  /* font-size: 4rem !important; */
  /* position: absolute; */
  bottom: 0;
}

.main-desc {
  margin-left: 18% !important;
  margin-top: 4%;
}

@media (min-width: 768px) and (max-width: 990px) {
  .video {
    width: 90vh;
  }
}

@media (max-width: 850px) {
  .App {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }
  .goToAdmissions{
    width: 50%;
  }
  /* .main-desc {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-top: 4%;
  } */
}
.goToAdmissions{
  width: 80%;
  height: 100%;
}
/* ******************************************************************** */

/* Small devices (landscape phones, 576px and up) */
/* Only for display flex of home-screen badges */
@media (min-width: 576px) and (max-width: 767.98px) {
  #conditional-flex {
    display: flex;
    margin: auto;
  }
  #no-padding-top {
    padding-top: 0 !important;
  }
  #no-padding-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .goToAdmissions{
    width: 50%;
  }
  .c-top-pic {
    padding-left: 0;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .main-desc {
    margin-left: 5% !important;
    padding-left: 0 !important;
    margin-right: 4% !important;
    margin-top: 8%;
  }

  .logo-nav {
    padding-left: 10px;
  }
  .card-body {
    padding: 0 !important;
  }

  .img-center {
    text-align: center;
    display: block !important;
  }
  .mobile-social-img {
    padding-left: 0 !important;
  }

  .no-pad-left {
    padding-left: 0 !important;
  }

  .c-top-pic {
    padding-left: 0 !important;
  }

  .c-main-text {
    margin-top: 12%;
    padding: 0 !important;
  }

  .c-parent_most-div {
    padding: 0 !important;
  }

  .mobile-order-2 {
    padding-top: 0 !important;
    margin-top: 3% !important;
    padding-bottom: 15%;
  }

  /* .dropdown:hover .dropdown-menu {
    border: 2px solid #536E40;
    text-align: right !important;
    margin-right: 10%;
  } */
  .my-card-img {
    padding-top: 20px !important;
    /* padding-bottom: 15px !important */
  }
  .t-center {
    text-align: center;
  }
  .contact-top-margin {
    margin-top: 10% !important;
    margin-bottom: 7% !important;
    margin: auto;
  }

  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }
  .banner-img {
    padding-top: 0 !important;
    margin-top: 5% !important;
  }

  /* navbar css */
  .noPad {
    padding: 0 !important;
  }
  .dropdown-menu {
    /* color: white !important; */
    background-color: #b6dbd7 !important;
    margin-left: -6% !important;
    text-align: right !important;
    padding-right: 20px !important;
  }

  .dropdown:hover .dropdown-menu {
    /* border: 2px solid #536e40; */
    border: transparent;
    text-align: right !important;
    padding-right: 10%;
  }
  .color-light-blue {
    background-color: #c9e9e6;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #006400 !important;
    font-weight: 600;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent !important;
  }
  .navbar-nav a {
    padding-right: 5% !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .App {
    overflow-x: hidden;
  }
  html {
    overflow-x: hidden !important;
  }
  .main-desc {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-top: 8%;
  }
  .social-logo {
    /* padding-left: 50%; */
  }

  /* .conditional-flex {
    display: flex;
    overflow-x: auto;
    margin: auto;
  } */

  /* .no-padding-top {
    padding-top: 0 !important;
  } */

  .c-top-pic {
    padding-left: 0;
  }

  /* .dropdown:hover .dropdown-menu {
    border: 2px solid #536E40;
    text-align: right !important;
    margin-right: 10%;
  } */
  .t-center {
    text-align: center;
  }
  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* .App {
    background-image: url("./images/homepage_bg.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: initial;
    background-position: top center;
    height: 100vh; 
  } */

  .logo-nav {
    padding-left: 13px;
  }
  .main-desc {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-top: 8%;
  }

  .c-main-desc {
    padding-left: 0;
  }

  /* .dropdown:hover .dropdown-menu {
    border: 2px solid #536E40;
    text-align: right !important;
    margin-right: 10%;
    margin-left: auto;
  } */
  .t-center {
    text-align: center;
  }
  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }

  /* navbar css */
  .noPad {
    padding: 0 !important;
  }
  .dropdown-menu {
    /* color: white !important; */
    background-color: #b6dbd7 !important;
    margin-left: -6% !important;
    text-align: right !important;
    padding-right: 20px !important;
  }

  .dropdown:hover .dropdown-menu {
    /* border: 2px solid #536e40; */
    border: transparent;
    text-align: right !important;
    padding-right: 10%;
  }
  .color-light-blue {
    background-color: #c9e9e6;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #006400 !important;
    font-weight: 600;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent !important;
  }
  .navbar-nav a {
    padding-right: 5% !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .App {
    overflow-x: hidden;
  }
  html {
    overflow-x: hidden !important;
  }
  .main-desc {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-top: 8%;
  }
  .social-logo {
    /* padding-left: 50%; */
  }

  /* .conditional-flex {
      display: flex;
      overflow-x: auto;
      margin: auto;
    } */

  /* .no-padding-top {
      padding-top: 0 !important;
    } */

  .c-top-pic {
    padding-left: 0;
  }

  /* .dropdown:hover .dropdown-menu {
      border: 2px solid #536E40;
      text-align: right !important;
      margin-right: 10%;
    } */
  .t-center {
    text-align: center;
  }
  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    background-image: url('./images/homepage_bg.png');
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: initial;
    background-position: top center;
    height: 50vh;
  }

  .logo-nav {
    padding-left: 17px;
  }

  .box-height {
    min-height: 250px;
  }

  .main-desc {
    margin-left: 15% !important;
    margin-top: 4%;
  }

  .c-main-text {
    padding-right: 0 !important;
  }

  .slide {
    padding-top: 3%;
    padding-bottom: 5%;
  }

  .t-center {
    text-align: left;
  }
  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }
  /* navbar css */
  .noPad {
    padding: 0 !important;
  }
  .dropdown-menu {
    background-color: #b6dbd7 !important;
    margin-left: -6% !important;
    text-align: right !important;
    padding-right: 20px !important;
  }

  .dropdown:hover .dropdown-menu {
    border: transparent;
    text-align: right !important;
    padding-right: 10%;
  }
  .color-light-blue {
    background-color: #c9e9e6;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #006400 !important;
    font-weight: 600;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent !important;
  }
  .navbar-nav a {
    padding-right: 5% !important;
  }
  .common-banner-text p {
    position: absolute !important;
    left: 3%;
    height: 100% !important;
    vertical-align: middle !important;
    padding-left: 7px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .App {
    overflow-x: hidden;
  }
  html {
    overflow-x: hidden !important;
  }
  .main-desc {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-top: 8%;
  }

  .goToAdmissions{
    width: 40%;
  }
  /* .conditional-flex {
      display: flex;
      overflow-x: auto;
      margin: auto;
    } */

  /* .no-padding-top {
      padding-top: 0 !important;
    } */

  .c-top-pic {
    padding-left: 0;
  }

  /* .dropdown:hover .dropdown-menu {
      border: 2px solid #536E40;
      text-align: right !important;
      margin-right: 10%;
    } */
  .t-center {
    text-align: center;
  }
  .nav-link.navActive {
    color: #006400 !important;
    font-size: 1.125rem;
    font-weight: 700 !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body {
    background-image: url('./images/homepage_bg.png');
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: initial;
    background-position: top center;
    height: 50vh;
  }
  .goToAdmissions {
    width: 35%;
  }
  .main-desc {
    margin-left: 16% !important;
    margin-top: 4%;
  }

  /* .navbar-nav > li > .dropdown-menu  {
    background-color: #6cad77;
  } */
  .box-height {
    min-height: 30vh;
  }

  .c-main-text {
    padding-right: 0 !important;
  }

  .t-center {
    text-align: left;
  }
  .navActive {
    border-top: 5px solid #536e40;
    padding-top: 5% !important;
  }
  /* ****************************** */
  .dropdown {
    margin-left: 0 !important;
  }
  .nav-item {
    margin-left: 12px !important;
  }
  .common-banner-text p {
    position: absolute;
    left: 5%;
    height: 100% !important;
    vertical-align: middle !important;
    padding-left: 7px !important;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #006400 !important;
    font-weight: 600;
  }
  .dropdown-item.active {
    background-color: white !important;
  }
  .social-logo {
    padding-left: 0 !important;
  }
}
@media (min-width: 1800px) {
  .common-banner-text p {
    font-size: 2.2rem !important;
  }
  .goToAdmissions {
    width: 35%;
  }
}
/* test */
/* .dropdown-item {
  color: white !important;
} */

.slide {
  background: #eeeeee;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* width: 80%; */
  /* margin: 30px; */
}
.carousel-item {
  text-align: center;
}
.slider-text {
  padding: 1.5%;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 2%;
  border-bottom: 2px solid black;
  font-size: 1.2em;
}
.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}
