/* padding top and bottom of navbar */
.nav-parent{
  margin-top: 10px !important;
  margin-bottom: 7px !important;
}

/* creating space between dropdown options */
.dropdown {
  margin-left: 20px;
}

/* making navbar options as black background */
.navbar a {
  color: black !important;
}

/* removing underline from hover */
a:hover {
  text-decoration: none !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dropdown:click .dropdown-menu {
  display: block;
  margin-top: 0;
}
.navbar-nav {
  text-align: right;
}

.icon-bar {
  width: 22px;
  height: 2px;
  background-color: #808080;
  display: block;
  transition: all 0.3s;
  margin-top: 4px;
}

.navbar-toggler {
  border: none;
  background: transparent !important;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.navbar-light .navbar-toggler {
  border-color: transparent !important
}